import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, HostListener } from "@angular/core"

import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"
import { AuthService } from "@puntaje/shared/core"
import { Router } from "@angular/router"

/*#################################

Menú arriba del sitio.

#################################*/

@Component({
    templateUrl: "topmenu.component.html",
    selector: "topmenu",
    styleUrls: ["topmenu.component.scss"]
})
export class TopmenuComponent extends MenuComponent {
    @Output() toggleTopmenuEvent = new EventEmitter()
    @ViewChild("topmenu") insideElement
    @HostListener("document:click", ["$event.target"]) public onClick(targetElement) {
        const clickedInside = this.insideElement.nativeElement.contains(targetElement)
        if (this.catchOutsideClick && !clickedInside) {
            this.toggleTopmenuEvent.emit()
        }
    }
    catchOutsideClick: boolean = false
    itemsMenu: any[]
    ITEMS_MENU = [
        {
            name: "topmenu.evaluar",
            route: "/pruebas/generar_prueba",
            icon: "editar-prueba"
        },
        {
            name: "topmenu.corregir",
            route: "/evaluacion_instancias/subir",
            icon: "upload"
        },
        {
            name: "topmenu.resultados",
            route: "/evaluaciones/resultados",
            icon: "resultados"
        },
        {
            name: "topmenu.planes_de_clase",
            route: "/plan_clases",
            icon: "planificacion-o"
        },
        {
            name: "topmenu.planes_de_clase_predefinidos",
            route: "/plan_clases_predefinidos",
            icon: "planificacion-o"
        },
        {
            name: "topmenu.mis_noticias",
            route: "/noticias",
            icon: "megafono-o"
        }
    ]

    @Input() config: any

    constructor(store: Store<State>, protected authService: AuthService, protected router: Router) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        if (this.authService.getEstablecimientos().length != 0) {
            this.ITEMS_MENU.push({
                name: "topmenu.resumen_de_uso",
                route: "/estadisticas/resumen_uso",
                icon: "establecimiento-o"
            })
        }
        this.ITEMS_MENU.push({
            name: "topmenu.ayudas",
            route: "/ayudas",
            icon: "libro2-o"
        })
        setTimeout(() => {
            this.catchOutsideClick = true
        }, 300)
        this.itemsMenu = this.ITEMS_MENU
    }
    onMenuClickOutside() {
        this.toggleTopmenuEvent.emit()
    }

    close() {
        this.toggleTopmenuEvent.emit()
    }
}
