<nav class="topmenu" #topmenu>
    <div class="overflow-menu-container">
        <div class="menu-options-container">
            <ul class="menu-options">
                <ng-container *ngFor="let item of itemsMenu">
                    <li routerLinkActive="active-menu">
                        <a [routerLink]="item.route" role="button">
                            <ogr-icon class="icono" name="{{ item.icon }}" align="top"></ogr-icon>
                            &nbsp;{{ item.name | t }}
                        </a>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
</nav>
