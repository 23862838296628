<footer>
    <div class="content-general">
        <div class="informacion">
            <!-- <div class="content-ayudas">
                <div class="list-ayuda" *ngFor="let ayuda of listaAyuda">
                    <h1>{{ ayuda.titulo | t }}</h1>
                    <a [href]="opcion.link" *ngFor="let opcion of ayuda.lista">
                        {{ opcion.nombre | t }}
                    </a>
                </div>
            </div> -->
            <div class="content-social-media">
                <img class="logo" src="{{ config.app.assets.logoLargeLight }}" />
                <!-- <div class="content-icono">
                    <a
                        href="{{ config.plataforma.info.social.youtube }}"
                        class="youtube"
                        target="_blank"
                        title="Youtube"
                    >
                        <fa name="youtube-play"></fa>
                    </a>
                    <a
                        href="{{ config.plataforma.info.social.instagram }}"
                        class="instagram"
                        target="_blank"
                        title="Instagram"
                    >
                        <fa name="instagram"></fa>
                    </a>
                </div> -->
            </div>
        </div>
        <div class="derechos-reservados">© Open Green Road. Todos os direitos reservados</div>
    </div>
</footer>
