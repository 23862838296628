import { Component, OnInit } from "@angular/core"

@Component({
    templateUrl: "./preguntas-frecuentes.component.html",
    styleUrls: ["./preguntas-frecuentes.component.scss"]
})
export class PreguntasFrecuentesComponent implements OnInit {
    seccionesPreguntas: any[]

    constructor() {}

    ngOnInit() {
        this.seccionesPreguntas = []
    }
}
