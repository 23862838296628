import { Component } from "@angular/core"
import { AppConfig, I18nService } from "@puntaje/shared/core"
declare const config: AppConfig

/*#################################

Footer, incluye información de la empresa.

#################################*/

@Component({
    templateUrl: "footer.component.html",
    selector: "footer-component",
    styleUrls: ["footer.component.scss"]
})
export class FooterComponent {
    supportMail: string
    contactPhone: string
    config: typeof config
    listaAyuda: any[]

    constructor(i18nService: I18nService) {
        this.config = config
        this.supportMail = config.plataforma.info.soporte.mail
        this.contactPhone = config.plataforma.info.contacto.telefono ? config.plataforma.info.contacto.telefono : ""
        this.listaAyuda = [
            /*  {
                titulo: "RECURSOS",
                lista: [
                    { nombre: "Guía do usuário", link: "" },
                    { nombre: "Tutoriais", link: "" },
                    { nombre: "Biblioteca", link: "" },
                    { nombre: "Planos de estudo", link: "" }
                ]
            }, */
            {
                titulo: "footer.ayuda.sobre.titulo",
                lista: [
                    { nombre: "footer.ayuda.sobre.item", link: "https://www.aprendolibre.com/" }
                    /*   { nombre: "FAQS", link: "" } */
                ]
            },
            {
                titulo: "footer.ayuda.soporte.titulo",
                lista: [
                    { nombre: "footer.ayuda.soporte.item", link: "condicionesUso" }
                    /*   { nombre: "Política de privacidade", link: "" },
                    { nombre: "Contato", link: "" } */
                ]
            }
        ]
    }
}
