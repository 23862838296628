<ll-titulo>Bem-vind@</ll-titulo>

<div class="row">
    <div class="col-md-4 col-sm-6">
        <ng-container *ngFor="let showcase of showcasesColumna[0]">
            <generic-showcasebox [showcase]="showcase"></generic-showcasebox>
        </ng-container>
    </div>
    <div class="col-md-4 col-sm-6">
        <ng-container *ngFor="let showcase of showcasesColumna[1]">
            <generic-showcasebox [showcase]="showcase"></generic-showcasebox>
        </ng-container>
    </div>
    <div class="col-md-4 col-sm-6">
        <ng-container *ngFor="let showcase of showcasesColumna[2]">
            <generic-showcasebox [showcase]="showcase"></generic-showcasebox>
        </ng-container>
    </div>
</div>